/* ==========================================================================
	Utility Mixins
	========================================================================== */

	.web-font( @font: @text-font, @stack: @sans-font-stack ) {
		font-family: @font, @stack;
		font-weight: normal;
	}

	.logo( @width, @height, @url: "/assets/img/logo.png" ) {
		width: @width;
		height: @height;
		display: block;
		padding: 0;

		text-indent: -10000px;
		background: url( @url );
		background-repeat: no-repeat;
	}

/* ==========================================================================
	Grayscale
	========================================================================= */

	.grayscale(@percent: 100%) {
		-webkit-filter: grayscale(@percent);
		-moz-filter: grayscale(@percent);
		-ms-filter: grayscale(@percent);
		-o-filter: grayscale(@percent);
		filter: grayscale(@percent);
		filter: url('/assets/img/grayscale.svg#grayscale');
		filter: gray;		
	}

	.grayscale-off(@percent: 0%) {
		-webkit-filter: grayscale(@percent);
		-moz-filter: grayscale(@percent);
		-ms-filter: grayscale(@percent);
		-o-filter: grayscale(@percent);
		filter: grayscale(@percent);
		filter: url('/assets/svg/grayscale.svg#notgrayscale');
		filter: gray;
	}

/* ==========================================================================
	Decorative Mixins
	========================================================================== */
	
	.glow( @size, @color ) {
		.box-shadow( 0 0 @size @color );
	}

	.overflow-wrap( @width: @gridRowWidth ) {
		width: 100%;
		min-height: 100%;
		overflow: hidden;

		@media all and ( max-width: @width ) {
			width: @width;
		}
	}

/* ==========================================================================
	Triangles
	========================================================================= */

	.arrow-up(@size, @color, @alt-size: @size) {
		width: 0; 
		height: 0; 
		border-left: @alt-size solid transparent;
		border-right: @alt-size solid transparent;
		
		border-bottom: @size solid @color;
	}

	.arrow-down(@size, @color, @alt-size: @size) {
		width: 0;
		height: 0;

		border-left: @alt-size solid transparent;
		border-right: @alt-size solid transparent;
		
		border-top: @size solid @color;
	}

	.arrow-right(@size, @color, @alt-size: @size) {
		width: 0; 
		height: 0; 
		border-top: @alt-size solid transparent;
		border-bottom: @alt-size solid transparent;
		
		border-left: @size solid @color;
	}

	.arrow-left(@size, @color, @alt-size: @size) {
		width: 0; 
		height: 0; 
		border-top: @alt-size solid transparent;
		border-bottom: @alt-size solid transparent; 
		
		border-right: @size solid @color; 
	}

/* ==========================================================================
	Nav Mixins
	========================================================================= */

	.separated-list( @spacing: 10px, @seperator: "|", @color: inherit, @size: inherit ) {
		& > * {
			&:before {
				content: @seperator;
				padding: 0 @spacing;
				color: @color;
				font-size: @size;
				vertical-align: top;
			}

			&:first-child {
				&:before {
					content: none;
				}
			}
		}
	}

/* ==========================================================================
	Column Mixins
	========================================================================= */

	.column-container(@count, @spacing: 0, @container-width: @full-column-width) {
		.clearfix();
		width: (@container-width + @spacing*2);
		position: relative;
		left: -@spacing;

		.column {
			width: percentage(1/@count);
			padding-left: @spacing;
			padding-right: @spacing;
			float: left;

			&:nth-child( @{count}n+1 ) {
				clear: left;
			}
		}
		
		.loop(@index) when(@index =< @count) {
			.column:nth-child(@{index}) .column-stretch {
				left: -(percentage(@index - 1));
				margin-left: -(@spacing * (@index - 1) * 2);
			}
			.loop(@index + 1);
		}

		.loop(1);
	}


/* ==========================================================================
	Column Mixins
	========================================================================= */

	.inline-column-container(@count, @spacing: 0, @container-width: @full-column-width) {
		.clearfix();
		width: (@container-width + @spacing*2);
		position: relative;
		left: -@spacing;

		.column {
			width: percentage(1/@count);
			padding-left: @spacing;
			padding-right: @spacing;
		}
	}

/* ==========================================================================
	Layout Mixins
	========================================================================= */

/* --------------------------------------------------------------------------
	Grid List Mixin
	------------------------------------------------------------------------- */

	.grid-list( @itemWidth, @itemCount, @totalWidth: @primary-column-width, @clearing: true ) when not ( @clearing ) {
		
		@margin:           (@totalWidth - (@itemWidth * @itemCount)) / ( (@itemCount * 2) - 2 );
		@marginLeft:       floor( @margin );
		@marginRight:      round( @margin );
		@adjustedWidth:    @totalWidth + @marginLeft + @marginRight;

		width: @adjustedWidth;
		overflow: hidden;
		position: relative;
		left: -@marginLeft;

		.grid-item  {
			margin: 10px 0;
			width: @itemWidth;
			margin-left: @marginLeft;
			margin-right: @marginRight;
		}
	}

	.grid-list( @itemWidth, @itemCount, @totalWidth: @primary-column-width, @clearing: true ) when ( @clearing ) {
		
		@margin:           (@totalWidth - (@itemWidth * @itemCount)) / ( (@itemCount * 2) - 2 );
		@marginLeft:       floor( @margin );
		@marginRight:      round( @margin );
		@adjustedWidth:    @totalWidth + @marginLeft + @marginRight;

		width: @adjustedWidth;
		overflow: hidden;
		position: relative;
		left: -@marginLeft;

		.grid-item  {
			margin: 10px 0;
			width: @itemWidth;
			margin-left: @marginLeft;
			margin-right: @marginRight;
		}
		
		.grid-item:nth-child( @{itemCount}n+1 ) {
			clear: both;
		}
	}

	.percentage-grid (@count, @margin) {
		@width: percentage( (1/@count) );

		width: 100%;
		position: static;

		.grid-item {
			width: @width;
			padding-left: (@margin/2);
			padding-right: (@margin/2);
			margin-left: 0;
			margin-right: 0;
			background: transparent;
			.box-sizing( border-box );
		}

		.grid-item:nth-child(@{count}n+1) {
			clear: both;
		}
	}


	.centered-carousel( @img-width, @item-width ) {
		@margin: (-(@img-width - @item-width)/2);
		.item {
			overflow: hidden;
			
			.container {
				width: @item-width;
				margin-left: auto;
				margin-right: auto;

				img {
					min-width: @img-width;
					max-width: none;
					margin-left: @margin;
				}
			}
		} 
	}

	/* --------------------------------------------------------------------------
		Sticky Footer Requirements:;
			html, body {
				height: 100%;
			}
			and apply .sticky-footer to the footer.
		------------------------------------------------------------------------- */
	
	.sticky-footer-container( @height: @footerHeight ) {
		min-height: 100%;
		position: relative;

		&:after {
			content: "";
			height: @height;
			display: block;
		}
	}

