/*
	This file is intended to easily overwrite Bootstrap's default,
	as well as to provide an easy way to edit the most commonly
	changed variables.

 */

/* ==========================================================================
	Layout
	========================================================================= */

	@primary-column-width:    700px;
	@secondary-column-width:  400px;
	@full-column-width:       (@primary-column-width + @secondary-column-width);

	@header-ribbon-height:    57px;
	@header-ribbon-slide:     50px;
	@header-ribbon-closed:    @header-ribbon-height - @header-ribbon-slide;
	
	@primary-header-height:   135px;
	@section-header-height:   260px;
	@footer-bar-height:       55px;

/* ==========================================================================
	Colors
	========================================================================= */

	// Colors
	// -------------------------
	@blue:            #41B6E6;//#559cbe;
	@dark-blue:       #002F6C;//#174256;
	@orange:          #E87722;//#c17132;
	@brown:           #8c8c8c;//#756b48;

	@brand-primary:      @blue;
	@brand-primary-dark: @dark-blue;
	@brand-alt:          @orange;
	@brand-ter:          @brown;

	// Grays
	// -------------------------
	@gray-darker:            #666666; 
	@gray-dark:              #8c8c8c;
	@gray:                   #999999; 
	@gray-light:             #B5B5B5; 
	@gray-lighter:           #ededed;

/* ==========================================================================
	Text
	========================================================================= */

	@body-bg:        #fff;
	@text-color:     @gray-dark;


	// Links
	// -------------------------
	@link-color:             @brand-primary;
	@link-hover-color:       darken(@brand-primary, 15%);


	// Fonts
	// -------------------------
	@text-font:        "Cabin";

/* ==========================================================================
	Defaults - Feel free to edit down here, but often you won't need to.
	========================================================================= */

/* ==========================================================================
	Media Break points
	========================================================================= */

	// Extra small screen / phone
	//** Deprecated `@screen-xs` as of v3.0.1

	@screen-phone-max:           677px;
	@screen-tablet-min:          (@screen-phone-max + 1);
	@screen-tablet-max:          768px;
	@screen-desktop-min:         1000px;

/* ==========================================================================
	Fonts
	========================================================================= */

	@sans-font-stack:        "Helvetica Neue", Helvetica, Arial, sans-serif;
	@serif-font-stack:       Georgia, "Times New Roman", Times, serif;
	@mono-font-stack:        Monaco, Menlo, Consolas, "Courier New", monospace;

	// Sizes
	// -------------------------
	@font-size-base:          18px;
	@font-size-large:         ceil(@font-size-base * 1.25); // 20
	@font-size-small:         ceil(@font-size-base * 0.85); // 14
	
	@line-height-base:        1.6;
	@line-height-computed:    floor(@font-size-base * @line-height-base); // ~20px	
