/* ==========================================================================
	Masthead
	========================================================================= */

	.frontpage-masthead {
		height: 425px;
		background-size: cover;
		background-position: center center;


		.banner-message {
			width: 630px;
		}

		.heading {
			font-size: 44px;
			line-height: 1.15;
			color: @brand-primary;
			font-weight: 700;
		}

		.btn-group {
			.btn {
				margin-left: 15px;
				&:first-child { margin-left: 0; }
			}
		}

		.list-columns {
			margin-top: 35px;

			.column-item {
				width: 50%;
				float: left;
				color: @brand-ter;
			}

			a:hover { color: @brand-alt; }
		}
	}

/* ==========================================================================
	Featured Boxes
	========================================================================= */

	.frontpage-features {
		@media screen and (max-width: 1199px) and (min-width: 678px){
			width: 100% !important;
			left: 0 !important;
			.column{
				width: 100% !important;
			}
		}
		.date { color: @gray-light; }

		.heading {
			margin: 15px 0;
		}

		.quote {
			quotes: "\201C""\201D""\2018""\2019";
			padding-left: 25px;
		    padding-top: 40px; // magic number :(
			
			position: relative;
			margin-top: 15px;

			&:before {
				content: open-quote;
				display: block;
				margin-left: -25px;
				
				position: absolute;
				top: 10px;
				left: 20px;

				font-family: "Georgia";
			    font-style: normal;
				font-size: 7em;
				line-height: 0.5em;
				color: fade(@brand-primary, 20%);
			}
		}

		.column-icon {
			height: 55px;
			margin-bottom: 15px;
		}

		.column-btn {
			margin-top: 15px;
		}

		// form slider
		.column-body {
			.slider-target {
				margin-top: 10px;
				left: -15px;
				right: -15px;
			}

			.gforms_confirmation_message {
				display: inline-block;
				width: 80%;
				vertical-align: top;

				p { margin: 0; }
			}
		}
	}

/* ==========================================================================
	Highlight Section
	========================================================================= */

	.frontpage-highlight {
		padding: 75px 0 55px;
		background: @gray-lighter;
	}


	// .frontpage-masthead {
	// 	display:none;
	// }

// 	div#pow {
//     margin-top: 200px;
// }