/* ==========================================================================
	Lists
	========================================================================= */



/* ==========================================================================
	Pagination
	========================================================================= */
	
	.pager li > a, 
	.pager li > span 
	{
		padding: 0;
		border: 0;
		border-radius: 0;

		&:hover {
			background: transparent;
		}
	}