/* ==========================================================================
	Standard
	========================================================================= */

	.slider-trigger {
		cursor: pointer;
	}

	.slider-target {
		display: block;
		overflow: hidden;

		position: absolute;
		z-index: 10;
	}

/* ==========================================================================
	Horizontal
	========================================================================= */

	.slider-group[data-slide="horizontal"] {
		.slider-target {
			width: 0;
			top: 0;

			&.animating { .transition( width .2s ) };
		}

		&.open > .slider-target {
			width: auto;
		}
	}

	.slider-group[data-dir="left"] .slider-target {
		right: 100%;
	}

	.slider-group[data-dir="right"] .slider-target {
		left: 100%;
	}	

/* ==========================================================================
	Dropdown Style
	========================================================================= */
	
	.slider-group[data-slide="vertical"] {
		.slider-target {
			height: 0;

			&.animating { .transition( all .2s ) };
		}

		&.open > .slider-target {
			height: auto;
		}
	}

	.slider-group[data-dir="down"] .slider-target {
		top: 100%;
	}

	.slider-group[data-dir="up"] .slider-target {
		bottom: 100%;
	}	

/* ==========================================================================
	Push Slider
	========================================================================= */

	.push-slider {

		&[data-slide="horizontal"] {
			.slider-target,
			.slider-trigger {
				display: inline-block;
				vertical-align: middle;
			}
		}

		.slider-target {
			position: relative;
		}

		&[data-dir="left"] .slider-target { right: 0; }
		&[data-dir="right"] .slider-target { left: 0; }	
		
		&[data-dir="down"] .slider-target { top: 0;	}
		&[data-dir="up"] .slider-target { bottom: 0; }	
	}

/* ==========================================================================
	Offscreen Slider
	========================================================================= */

	.slider-group.offscreen {

		.slider-target {
			position: fixed;
			width: auto;
			&.animating { .transition( all .2s ) };
		}

		&[data-dir="left"] .slider-target { .translate(100% ,0); right: 0; left: initial; }
		&[data-dir="right"] .slider-target { .translate(-100% ,0); left: 0; right: initial; }
		
		&[data-dir="up"] .slider-target { .translate(0 ,100%); bottom: 0; top: initial; }	
		&[data-dir="down"] .slider-target { .translate(0 ,-100%); top: 0; bottom: initial; }

		&.open[data-dir="left"] .slider-target { .translate(0 ,0); }
		&.open[data-dir="right"] .slider-target { .translate(0 ,0); }	
		
		&.open[data-dir="down"] .slider-target { .translate(0 ,0); }
		&.open[data-dir="up"] .slider-target { .translate(0 ,0); }	

	}	