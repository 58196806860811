/* ==========================================================================
	Ribbon
	========================================================================= */

.header-ribbon {
	background: @brand-alt;
	height: @header-ribbon-height;

	margin-top: -@header-ribbon-slide;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	.z-top;

	.transition(all 0.3s);

	* {
		line-height: 55px;
		vertical-align: middle;
	}

	.ribbon-link {
		&:hover {
			color: @gray-lighter;
		}
	}

	.faux-link,
	.close-link {
		top: 0;
		color: darken(@brand-alt, 25%);
	}

	.alt-site-logo {
		display: inline-block;
		width: 150px;
		height: 36px;

		margin: 0 15px;

		svg {
			display: block;
			pointer-events: none;
		}

		.group {
			fill: #fff;
		}
	}

	.logo-main{
		margin-top: -50px;
	}

	.faux-link {
		display: inline-block;
		padding-left: 15px;
		border-left: 1px solid #000;
		line-height: 1.6;
	}

	.open-link {
		content: "";
		.arrow-down(10px, @brand-alt);
		position: absolute;
		top: @header-ribbon-height;
		right: 0;
	}

	.close-link {
		display: none;
	}

	// Open State
	.ribbon-open & {
		margin-top: 0;
		.close-link {
			display: block;
		}
		.open-link {
			top: 0;
		}
	}
}

.header-ribbon-alt {
	background: @brand-alt;
	height: @header-ribbon-height;

	margin-top: -@header-ribbon-slide;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 20;
}

.home.page.ribbon-open .primary-header {
	top: 57px;
	
}

.home.page.ribbon-open .primary-header + * {
	//margin-top: 192px;
}

.page.ribbon-open .primary-header {
	top: 5px;
}

.page.ribbon-open .primary-header + * {
	margin-top: 199px;
}

@media (max-width: 676px) {
	.page.ribbon-open .primary-header + * {
		margin-top: 0;
	}

	.section-nav {
		top: -15px;
	}
}

@media screen and (max-width: 1000px){
	.home.page.ribbon-open .primary-header {
		top: 0;
	}
}
/* ==========================================================================
	Header
	========================================================================= */

.primary-header {
	width: 100%;
	height: @primary-header-height;
	padding: 25px 0 0;

	position: fixed;
	top: @header-ribbon-closed;
	.z-top-mid;

	background: #fff;

	.transition(all 0.3s);

	.ribbon-open & {
		top: @header-ribbon-height;
	}
	+ * {
		margin-top: @primary-header-height + @header-ribbon-closed;
		.transition(all 0.3s);
	}

	.ribbon-open & + * {
		margin-top: @primary-header-height + @header-ribbon-height;
	}

	.page-template-page-bannerless &,
	.page-template-page-blog & {
		border-bottom: 1px solid @gray-lighter;
	}
}

/* ==========================================================================
	Admin Bar
	========================================================================= */

.admin-bar {
	.primary-header {
		top: 32px + @header-ribbon-closed;
	}
	.header-ribbon {
		top: 32px;
	} // admin bar size

	&.ribbon-open {
		.primary-header {
			top: 32px + @header-ribbon-height;
		}
	}
}

/* ==========================================================================
	Logo
	========================================================================= */

.site-logo {
	width: 270px;
	margin-top: -60px;
}

/* ==========================================================================
	Page Header
	========================================================================= */

.section-header {
	height: @section-header-height;
	background-size: cover;
	background-repeat: no-repeat;
}

.section-header-container {
	height: @section-header-height;
	padding-top: 45px;
}

.section-heading {
	max-width: 60%;
}

/* ==========================================================================
	Social Sharing
	========================================================================= */

.social-share-list {
	body.job-opportunities & {
		display: none;
	}

	position: absolute;
	top: 25px;
	right: 0;

	.page-template-page-blog &,
	.single-post & {
		top: 0;
	}

	.social-share-item {
		margin-top: 1px;
		.translate(90px, 0);

		color: #fff;
		background-color: @brand-alt;
		border-radius: 10px 0 0 10px;

		&:first-child {
			margin-top: 0;
		}
		.transition(all 0.3s);

		&:hover {
			.translate(0, 0);
		}

		&::before{
			content: "";
		}
	}

	.share-link {
		color: #fff;
		padding: 7px;
	}

	.icon {
		width: 20px;
		height: 20px;
		margin-right: 10px;
		fill: #fff;
		vertical-align: middle;
	}

	.share-name {
		display: inline-block;
		width: 75px;
		vertical-align: middle;
	}
}

.blog-share-holder {
	height: 225px;
	width: 124px;
	position: absolute;
	top: 250px;
	right: 0;
	pointer-events: none;

	.social-share-item {
		pointer-events: all;
	}
}

/* ==========================================================================
	Search Forms
	========================================================================= */

.header-search {
	width: 274px;
	position: relative;

	margin-left: 24px;
	margin-top: 10px;

	.search-container {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;

		.transition(all 0.3s);

		&.active {
			position: relative;
			.z-top-mid;
		}

		.search-selecting & {
			top: 100%;
		}
	}

	.single-line-form {
		.input {
			border: 1px solid @gray-light;
			padding: 3px 40px;
			border-radius: 5px;
		}
		.submit {
			border-radius: 0 5px 5px 0;
		}
	}

	.selector-icon-holder {
		width: 35px;
		height: 100%;
		position: absolute;

		.icon {
			display: block;
			width: 15px;
			margin-left: 10px;
		}
	}
}

.hidden-submit .submit {
	visibility: hidden;
	opacity: 0;
	.transition(~"opacity 3s, visibility 0s");

	.will-search& {
		visibility: visible;
		opacity: 1;
	}
}
