@media (min-width: @screen-desktop-min) {
	/* ==========================================================================
	General
	========================================================================= */
	// Annoying bootstrap override that I'm unhappy about.
	.container {
		max-width: @full-column-width;
		width: @full-column-width;
	}

	.hidden-desktop {
		display: none;
	}
	.desktop-left {
		float: left;
	}
	.desktop-right {
		float: right;
	}

	/* ==========================================================================
	Columns
	========================================================================= */

	.primary-column {
		width: @primary-column-width;
		float: left;
	}

	.secondary-column {
		width: @secondary-column-width;
		float: left;
	}

	.full-column {
		width: @full-column-width;
	}
}
