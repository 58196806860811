/* ==========================================================================
	General
	========================================================================= */

	* {
		.box-sizing(border-box);
	}

	html, body {
		min-height: 100%;
	}

	body {
		width: 100%;
		color: @text-color;
		font-family: @text-font;
		font-size: @font-size-base;
		font-weight: 300;
		line-height: @line-height-base;
		
		&.open-slider {
			overflow: hidden;
		}
	}

	hr, .hr {
		border-bottom: 1px solid @brand-primary;
	}

	a {
		color: @brand-primary;
		text-decoration: none;
		.transition( all .3s );

		&:hover {
			text-decoration: underline;
		}
	}

	p {
		margin: 0 0 1em;
	}

	address {
		font-style: normal;
	}

/* ==========================================================================
	Utilities
	========================================================================= */

	.content {
		img { max-width: 100%; }
	}

	.container {
		.clearfix;
		width: @full-column-width;
		margin-right: auto;
		margin-left: auto;
	}

	.faux-body {

	}

	.clearfix {
		&:before,
		&:after {
			content: " "; 
			display: table;
		}
		&:after {
			clear: both;
		}
	}

	.grayscale {
		.grayscale();
	}

	.svg {
		display: block;
	}