/* ==========================================================================
	General Styles
	========================================================================= */

	body {
		width: 100%;
	}

	.admin-edit-post {
		position: absolute;
		color: white;
		background: rgba(0,0,0,0.5);
		padding: 5px 10px;
	}

/* ==========================================================================
	Mixin Components
	========================================================================= */

	.sticky-footer {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}

	.horizontal-list {
		.clearfix;
		padding: 0;
		margin: 0;
		& > * { 
			display: block; 
			float: left; 
		}
	}

/* ==========================================================================
	Layout Styles
	========================================================================= */

	.all-left { float: left; }
	.all-right { float: right; }

	.cleared-left { clear: left; }
	.cleared-right { clear: right; }
	.cleared { clear: both; }

	.relative { position: relative; }
	.fixed { position: fixed; }
	.absolute { position: absolute; }
	.static { position: static; }

	.block { display: block; }
	.inline-block { display: inline-block; }
	.hidden { display: none; }

	.no-font { font-size: 0; } // Inline-block space fix utility

	.table { display: table; }
	.table-cell { display: table-cell; }

	.cover {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.hide-overflow { overflow: hidden; }

	.menu {
		padding: 0;
		.clearfix;
		
		list-style: none;
		margin: 0;

		.menu-column {
			float: left;
			margin-right: 35px;
		}
	}

	.stretchy {
		padding-bottom: 1000px;
		margin-bottom: -1000px;
	}

/* ==========================================================================
	Vertical Spacing
	========================================================================= */

	.vert-center {
		position: relative;
		top: 50%;
		.translate(0, -50%);
	}

/* ==========================================================================
	Text Styles
	========================================================================= */

	.uppercase { text-transform: uppercase; }
	.lowercase { text-transform: lowercase; }

	.nowrap { white-space: nowrap; }

	.txt-left { text-align: left; }
	.txt-center { text-align: center; }
	.txt-right { text-align: right; }
	.txt-justify { text-align: justify; }

	.txt-middle { vertical-align: middle; }

/* ==========================================================================
	Reset Styles
	========================================================================= */

	.no-hover {
		&:hover {
			text-decoration: none;
			color: inherit;
		}
	}

	.no-list {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.docked-list {
		padding-left: 20px;
	}

	.hidden-text {
		text-indent: -10000px;
	}

	.pointer {
		cursor: pointer;
	}

/* ==========================================================================
	Colors
	========================================================================= */

	.txt-white { color: #fff; }
	.txt-black { color: #000; }	
	.txt-color { color: @text-color; }
	.txt-brand { color: @brand-primary; }
	.txt-alt { color: @brand-alt; }
	.txt-ter { color: @brand-ter; }
	
	.white-fill { fill: #fff; }	
	.brand-fill { fill: @brand-primary; }	
	.alt-fill { fill: @brand-alt; }	
	.ter-fill { fill: @brand-ter; }	

	.italic { font-style: italic; }

/* ==========================================================================
	Shapes
	========================================================================= */

	.circle {
		border-radius: 50%;
	}

	.rounded {
		border-radius: 10px;
	}

/* ==========================================================================
	Scroll Over
	========================================================================= */

	.scroll-over {
		width: 100%;
		background: #fff;
	}

/* ==========================================================================
	Relative Space
	========================================================================= */

	.top { top: 0; }
	.right { right: 0; }
	.bottom { bottom: 0; }
	.left { left: 0; }

/* ==========================================================================
	Z Space
	========================================================================= */

	.z-top { z-index: 500 }
	.z-top-mid { z-index: 400 }
	.z-mid { z-index: 300 }
	.z-bottom-mid { z-index: 200 }
	.z-bottom { z-index: 100 }

/* =============================================================================
	WordPress Generated Classes
	See: http://codex.wordpress.org/CSS#WordPress_Generated_Classes
	========================================================================== */

	.aligncenter { display: block; margin: 0 auto; }
	.alignleft { margin: 0 20px 10px 0; float: left; }
	.alignright { margin: 0 0 20px 10px; float: right; }
