.btn(@color; @background; @border) {
	display: inline-block;
	padding: 6px 12px;

	margin-bottom: 0;
	
	text-align: center;
	vertical-align: middle;
	cursor: pointer;

	background-image: none;
	background-color: @background;
	
	color: @color;
	white-space: nowrap;
	line-height: 1;

	border: 1px solid transparent;
	border-color: @border;	
	border-radius: 4px;
	
	.user-select(none);

	&:hover,
	&:focus,
	&:active,
	&.active {
		color: @color;
		background-color: darken(@background, 10%);
		border-color: darken(@border, 12%);
		text-decoration: none;
	}


	&:active,
	&.active {
		background-image: none;
	}
	
	&,
	&:active,
	&.active {
		&:focus {
			outline: thin dotted;
			outline: 5px auto -webkit-focus-ring-color;
			outline-offset: -2px;
		}
	}

	&:active,
	&.active {
		outline: 0;
		background-image: none;
		.box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
	}
}