/* ==========================================================================
	Base
	========================================================================= */

	.primary-column {
		width: @primary-column-width;
		float: left
	}

	.secondary-column {
		width: @secondary-column-width;
		float: left;

		.primary-column + & { padding-left: 60px; }

		&.separated {
			width: @secondary-column-width - 60px;
			margin-left: 60px;
			border-left: 3px solid @gray-lighter;

			&.sm { width: @secondary-column-width - 160px;	}
		}

		&.separated-right {
			width: @secondary-column-width - 60px;
			margin-right: 60px;
			padding-right: 60px;
			border-right: 3px solid @gray-lighter;

			&.sm { width: @secondary-column-width - 160px; }
		}

		&.sm {
			width: @secondary-column-width - 100px;
			& + .primary-column { width: @primary-column-width + 100px; }
		}
	}

	.full-column {
		width: @full-column-width;
	}

/* ==========================================================================
	Sections
	========================================================================= */

	.section {
		padding-top: 70px;
		padding-bottom: 70px;
	}

	.sm-section {
		padding-top: 35px;
		padding-bottom: 35px;
	}	

/* ==========================================================================
	General
	========================================================================= */

	.icon-caret {
		.icon-caret(height, .65em);
		// use the mixin above to set the width/height appropriately,
		// then reset the height to 100% so it will sit in the middle nicely
		height: 100%; 
		fill: #fff;

	    position: relative;
	    // top: -.15em;
        left: .05em;
	}

	.caret-circle {
		display: inline-block;
		width: 1em;
		height: 1em;

		text-align: center;
	    vertical-align: middle;
	    line-height: 1;

		border-radius: 50%;
		background-color: @brand-primary-dark;

		position: relative;
		top: -.15em;

		&.caret-after { margin-left: 5px; }
		&.caret-before { margin-right: 5px;	}

		&.left { 
			.icon {
				.rotate(180deg); 
			} 
		}

		&.down { 
			.icon {
				.rotate(90deg); 
				top: -.1em;
			} 
		}

		&.up { 
			.icon {
				.rotate(-90deg); 
				top: -.05em;
				left: -.01em;
			} 
		}
	}

/* ==========================================================================
	More Link Header
	========================================================================= */

	.more-link-header {
		height: 1.3em;

		.heading-link {
			color: @gray-light;
			&:hover { color: @gray; }
		}

		&:after {
			content: "";
			display: inline-block;
			width: 100%;
			height: 0;
			font-size: 0;
		}
	}

/* ==========================================================================
	Image Grid
	========================================================================= */

	.grid-message {
		border-color: @brand-ter;
	}

	.img-grid {
		.grid-item {
			display: inline-block;
			width: 25%;
		    margin: 25px 0;

			vertical-align: middle;
			text-align: center;
		}

		.img-item {
			max-width: 100%;
			max-height: 100%;
			vertical-align: middle;
		}
	}

/* ==========================================================================
	Buttons
	========================================================================= */

	.alt-btn {
		.btn(#fff, @brand-alt, transparent);
	}

	.ter-btn {
		.btn(#fff, @brand-ter, transparent);
	}

	.brand-btn-dark {
		.btn(#fff, @brand-primary-dark, transparent);
	}	

	.border-btn {
		.btn(#fff, transparent, #fff);
	}		

	.brand-btn {
		.btn(#fff, @brand-primary, transparent);
	
		.text {
			content: "";
			display: inline-block;
			
			padding-right: 10px;
			margin-right: 10px;
			border-right: 1px solid @brand-primary-dark;
			vertical-align: middle;
		}

		.caret {
			display: inline-block;
			height: 1.3em;
			width: 1.3em;
			
			position: static;
			border-radius: 50%;
			background-color: @brand-primary-dark;
		}
	}

	.btn {
		padding: 7px 20px;	
	}

/* ==========================================================================
	Graphic // Animation
	========================================================================= */

	.page-graphic-container {
		height: 262px;
		width: 262px;
		
		.animated-clover {
			.rotate(0deg);
			.transition(all 1.5s);

			&.rotated { .rotate(90deg); }
			
			.top { .translate(0, -40px); }
			.right { .translate(40px, 0); }
			.bottom { .translate(0, 40px); }
			.left { .translate(-40px, 0); }
			
			.group {
				.transition(all 1s);

				.text { 
					white-space: nowrap;
					opacity: 1;
					.transition(all 1s ease 1s); 
				}

				&.left .test {
					.translate(120px, 175px);
				}
			}

			.closed {
				.text { opacity: 0; }
				.translate(0, 0);
			}
		}
	}

/* ==========================================================================
	Simple Features
	========================================================================= */

	.simple-features {
		border-top: 5px solid @gray-lighter;
		border-bottom: 5px solid @gray-lighter;

		.feature {
			@height: 200px;
			@spacing: 30px;

			width: 33.33%;
			height: @height;
			position: relative;
			text-align: center;
			overflow: hidden;

			&:nth-child(n+2) .main {
				border-left: 1px solid @gray-lighter;
			}

			.main {
				padding: (@spacing/2) @spacing;
				margin: (@spacing/2) 0;
				height: 170px;
				position: relative;

				.title {
					margin: 0;
					line-height: 1;
					color: @brand-ter;
				}

				.caret-circle {
					position: absolute;
					bottom: 15px;
					top: initial;
					margin-left: -.625em;
				    font-size: 1.25em;

				}
			}

			.slide {
				height: @height;
				padding: @spacing;

				position: absolute;
				top: 0;
			    right: 0;
				left: 0;
				
				color: #fff;
				background-color: @brand-alt;
				
				.transition(all .3s);
				.translate(0, 100%);

				.title {
					color: lighten(@brand-alt, 25%);
					margin-bottom: .5em;
				}
			}

			&.mobile-hover,
			.no-touch &:focus,
			.no-touch &:hover {
				.slide { .translate(0, 0); }
			}
		}
	}

/* ==========================================================================
	Team // Grid
	========================================================================= */

	.department-group {
		margin-bottom: 30px;

		.team-header {
			color: @brand-alt;

			&:after, &:before {
				color: @brand-ter;
			}
		}
	}

	.team-grid {
		//.column-container(4, 10px);
		display: flex;
		flex-wrap: wrap;

		.team-spacer{
			flex-basis: 100%;
  			height: 0;
		}

		.team-item {
			flex: 0 0 25%;
			box-sizing: border-box;
			padding: 10px;
			&:hover {
				.team-name { background-color: @brand-primary-dark; }
			}
		}

		.team-name {
			display: block;
			padding: 5px 0;

			position: absolute;
			right: 10%;
			bottom: 20px;
			left: 10%;

			text-align: center;
			background-color: fade(@brand-primary, 90%);
			border-radius: 5px;
			.transition(all .3s);
		}

		.grid-img {
			max-width: 100%;
		}
	}

/* ==========================================================================
	Team // Bio
	========================================================================= */

	.bio-content {
		position: fixed;
		overflow: scroll;
		
		top: 100%;		
		right: 0;
		bottom: 0;
		left: 0;

		.open & { top: @primary-header-height + @header-ribbon-closed; }
		.ribbon-open .open & { top: @primary-header-height + @header-ribbon-height; }

		background: @gray-lighter;

		.back-link {
		    display: inline-block;
			margin: 50px 0;
			color: @brand-primary-dark;
		}

		.bio-header {
			padding: 0 0 15px;
			margin: 0 0 15px;
			border-bottom: 1px solid @gray-light;
		}

		.bio-name {
			margin: 0;
		    font-size: 42px;
		}

		.bio-title {
			margin-left: 10px;
			vertical-align: baseline;
			color: @brand-ter;
		}

		.bio-img {
			margin-bottom: 15px;
		}

		.social-media-item {
			margin-right: 10px;
		}

		.social-link {
			display: block;
			height: 35px;
			width: 35px;

			text-align: center;

			border-radius: 50%;
			background-color: @brand-primary;

			.icon-email{ .icon-email(width, 20px); }
			.icon-linkedin{ .icon-linkedin(width, 20px); }

			&:hover { background-color: @brand-primary-dark; }
		}
	}

/* ==========================================================================
	Testimonials
	========================================================================= */

	.testimonial {
		.testimonial-logo {
			max-width: 100%;
			max-height: 200px;
		}
	}

/* ==========================================================================
	Dowload Link
	========================================================================= */

	.download-link {
		.icon-download {
			.icon-download(height, 2em);
			vertical-align: text-bottom;
			margin-right: .25em;
		}

		&:hover {
			.icon-download {
				fill: @brand-primary;
			}
		}
	}

/* ==========================================================================
	Form Bars
	========================================================================= */

	.form-wrapper {
		padding: 35px;
		background-color: lighten(@brand-primary, 10%);
		box-shadow: 3px 5px 5px 0px fade(#000, 50%);
		border-radius: 10px;

		.gform_wrapper { margin: 0 !important; }
		.gform_footer { 
			margin: 0 !important;
			padding-bottom: 0 !important;
			text-align: center; 
		}

		&.sm-wrapper { 
			padding: 15px;
			box-shadow: none;
		}

		a:hover {
			color: @brand-primary-dark;
		}

		.gform_wrapper .gfield textarea {
			height: 10em !important;
		}
	}	
	.form-header {
		margin-bottom: .5em;
	}

	.form-intro {
		color: @brand-primary-dark;
	}

/* ==========================================================================
	Office List // Sidebar
	========================================================================= */

	.offices-lg {
		.office {
			line-height: 1.3;

			.single-line-offices & {
				margin-top: .5em;
			}

			.offices-column & {
				margin-top: 25px;
				&:first-child { margin-top: 0; }
			}
		}

		.single-line-offices & {
			.no-break br {
				display: none;
			}

			.header {
				text-transform: none;
			}

			.address,
			.phone {
				margin-left: .5em;
			}

			.header,
			.phone {
				display: inline-block;
			}
		}
	}	

/* ==========================================================================
	Map Header
	========================================================================= */

	.map-canvas {
		width: 100%;
		height: 100%;

		.marker { display: none; } // prevents a flash of text
	}

/* ==========================================================================
	Blog // Features Post
	========================================================================= */

	.ter-overlay() {
		.transition(all .3s);
		background-color: fade(@brand-ter, 60%);
	}

	.featured-post {
		height: 460px;
		position: relative;

		.post-info {
			width: @secondary-column-width;
			height: 100%;
			padding: 40px 50px;
			background: @brand-primary;

			position: absolute;
			top: 0;
			right: 0;

			&:before, &:after {
				content: "";
				display: block;
				width: @primary-column-width;
				height: 100%;
				
				position: absolute;
				top: 0;
				right: @secondary-column-width;
			}

			&:before {
				z-index: -2;
				background-size: cover;
			}

			&:after {
				z-index: -1;
				.ter-overlay();
			}			
		}

		.post-title { 
			margin: .5em 0;
			line-height: 1.2;
			font-size: 50px; 

			a:hover {
				color: @gray-lighter;
			}
		}

		.post-meta { color: @brand-primary-dark; }

		.btn:hover {
			background-color: @brand-primary-dark;
		}
	}

/* ==========================================================================
	Blog // Filter Bar
	========================================================================= */

	.filter-bar {
		margin: 40px 0;

		.page-headline { margin: 0; }

		.blog-select-container {
			min-width: 265px;
		}
	}

/* ==========================================================================
	Blog // List
	========================================================================= */

	.blog-list {
		.inline-column-container(3, 15px);
		margin-top: -15px;

		.post-item {
			vertical-align: top;
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}

	.mix {
		display: none;
	}

	.post-inner {
		height: 220px;
		padding: 30px;
		background-color: @gray-lighter;

		&:hover { background-color: darken(@gray-lighter, 15%); }

		.post-meta-tag {
			padding: 5px 30px;
			border-radius: 0 0 10px 0;
			background-color: @gray;

			.careers-networking & { background-color: @brand-primary-dark; }
		}

		.post-title {
			margin: 50px 0 0;
			line-height: 1.2;
		}

		&.img-post {
			position: relative;
			background-color: transparent;

			.post-title { color: #fff; }

			&:before {
				content: "";
				.cover;
				z-index: -2;
				background-size: cover;
				background-position: center center;
			}

			&:after {
				content: "";
				.cover;
				z-index: -1;
				.ter-overlay();
			}

			&:hover:after { background-color: fade(@brand-ter, 80%); }
		}
	}

/* ==========================================================================
	Blog // Single
	========================================================================= */

	.post-header {
		position: relative;
		padding: 25px 0;
		margin-bottom: 25px;
		border-top: 4px solid @brand-primary;
		border-bottom: 1px solid @gray-lighter;

		.post-title {
			margin: 0;
			padding-right: 200px; // Dead space for back button
		}

		.back-link {
			position: absolute;
			top: 30px;
			right: 0;

			color: @brand-primary-dark;
		}

		.post-meta-list {
			margin-top: 20px;

			.meta-item:not(:first-child):before {
				content: "\2022";
				display: inline-block;

				margin-left: 8px;
				margin-right: 8px;
			}
		}

		&.has-cta {
			padding: 25px 0 0;
			border-bottom: 0;			
		}
	}

	.post-cta {
		margin-top: 25px;
		background: @gray-lighter;
		padding: 15px;
		color: @brand-ter;

		.cta-link {
			.caret-circle { 
				margin-left: .2em;
				background: @brand-alt; 
				.transition(background .3s);
			}

			&:hover {
				color: @brand-primary;
				.caret-circle { background: @brand-primary; }
			}
		}

	}

/* ==========================================================================
	Photo Carousel // Events
	========================================================================= */

	.photo-carousel	{
		height: 350px;
		width: 100%;

		.carousel-inner, 
		.item {
			height: 100%;
			background-size: cover;
		}

		.carousel-caption {
			width: 100%;
			padding: .5em 1em;
			
			right: 0;
			bottom: 0;
		    left: 0;
			
			text-align: left;
			line-height: 1;
			background-color: fade(#000, 80%);
		}

		.carousel-indicators {
		    width: auto;
			bottom: .5em;
			margin: 0;
			right: 1em;
			left: initial;
			line-height: 1;
			font-size: .75em;

			.indicator {
				margin-left: 5px;
				&:before { content: none; }
			}
		}
	}

/* ==========================================================================
	Events List
	========================================================================= */

	.events-list {
		.event-item:not(:first-child) {
			margin-top: 20px;
		}
	}

/* ==========================================================================
	FAQs
	========================================================================= */

	.faq-list {
		border-top: 5px solid @brand-primary;
	}

	.faq {
		padding: 15px;
		background: #fff;
		&:nth-child(even) { background: @gray-lighter; }
	
		.faq-q {
			position: relative;
	
			.caret-after {
				position: absolute;
				top: 0;
				right: 15px;
				bottom: 0;
				margin: auto;
				.transition(all .3s);

				.open& { .rotate(90deg); }
			}
		}

		.faq-a .content {
			padding: 15px 30px 0;
		}
	}

/* ==========================================================================
	Integration Frames // Job Listing // Resume // iFrames
	========================================================================= */

	.integration-frame {
		display: block;
		width: 100%;
		min-height: 500px;
		border: 0;
	}
