@media (min-width: @screen-phone-max) and (max-width: @screen-desktop-min) {
	/* ==========================================================================
	Utility
	========================================================================= */

	.hidden-tablet {
		display: none;
	}
	.tablet-left {
		float: left;
	}
	.tablet-right {
		float: right;
	}

	.horizontal-list {
		&.vertical-tablet > * {
			float: none;
		}
	}

	.grid-list-reset-tablet {
		width: 100%;
		overflow: initial;
		position: static;
	}

	.container {
		max-wdith: 100%;
		width: 100%;
		padding: 0 15px;
	}

	.hidden-desktop.hidden-tablet {
		display: block;
	}

	nav.utility-nav.desktop-right.tablet-right {
		float: none;
	}
	nav.primary-nav.desktop-right.tablet-right {
		float: none;
	}

	.page.ribbon-open .primary-header + * {
		margin-top: 0;
	}

	.section-nav {
		top: 20px;
	}

	/* ==========================================================================
	General
	========================================================================= */

	.primary-column {
		width: 60%;
		float: left;
	}

	.secondary-column {
		width: 35%;
		margin-right: 5%;
		float: left;
	}
} // End Query
