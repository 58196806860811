/* ==========================================================================
	Mixins
	========================================================================= */

	.default-input-state() {
		.base-txt;
		width: 100%;
		height: auto;
		padding: 5px;
		
		line-height: 1.6em;
		color: @text-color;
		
		border-radius: 5px;
		border: 1px solid @brand-primary;
	}

/* ==========================================================================
	General
	========================================================================= */

	input[type="submit"] {
		.btn( #fff, @brand-primary-dark, darken(@brand-primary-dark, 2%) );	
		padding: 8px 18px;
		.uppercase;
	}

	input, select {
		color: @text-color;
	}

/* ==========================================================================
	Gravity Forms Resets
	========================================================================= */

	.gform_wrapper {
		span.gfield_required {
			color: @brand-primary;
		}

		.ginput_complex label, 
		.gfield_time_hour label, 
		.gfield_time_minute label, 
		.gfield_date_month label, 
		.gfield_date_day label, 
		.gfield_date_year label, 
		.instruction {
			margin: -1em 0 1.5em;
		}

		.gfield input[type="text"], 
		.gfield input[type="url"], 
		.gfield input[type="email"], 
		.gfield input[type="tel"], 
		.gfield input[type="number"], 
		.gfield select, 
		.gfield textarea,
		.gfield input[type="file"],
		.gfield input[type="password"] {
			.default-input-state() !important;
		}

		//	Prevents some Selects from being cutoff, or too small
		&.gf_browser_chrome .ginput_complex select,
		&.gf_browser_chrome .ginput_complex .ginput_right select {
			margin-top: 1px;
			height: 23px;
		}

		//	More clearly associates the secondary labels with their fields.
		.ginput_complex .ginput_left, 
		.ginput_complex .ginput_right, 
		.ginput_complex .ginput_full {
			margin-bottom: 8px;

			span[class*="input"] {
				margin-bottom: 8px;
			}

			label {
				margin: 2px 0;
			}

			input,
			select {
				margin: 0;
			}
		}

		//	Checked radio/checkboxes are given a bold style by GF
		ul.gfield_radio li input[type="radio"]:checked + label, 
		ul.gfield_checkbox li input[type="checkbox"]:checked + label {
			font-weight: normal;
		}

		ul.gfield_checkbox li, 
		ul.gfield_radio li {
			overflow: visible !important;	// I don't like this any more than you do
		}
	}

/* ==========================================================================
	File Upload Normalization
	========================================================================= */

	.gform_wrapper input[type="file"] {
		line-height: 12px;
		font-size: 12px;
		padding: 0 !important;	// Don't judge me.

		border: 1px solid #cccccc;
		.box-shadow( inset 0 1px 1px rgba(0, 0, 0, .15) );
		color: @gray;
		position: relative;
	}

	.gform_wrapper input[type=file]::-webkit-file-upload-button {
		-webkit-appearance: square-button;
		padding: 7px;
		
		position: relative;
		left: 100%;
		
		font-size: 12px;
		background: @brand-primary;
		color: #fff;
		border: 0;
		// Has to be a box-shadow to overcome border-boxing.
		.box-shadow( ~"0 -1px 0  @brand-primary, 0 1px 0 @brand-primary" );
	}

/* ==========================================================================
	Added Classes
	========================================================================= */
	//	Add these within gravity forms.

	.gform_wrapper .inline-inputs .ginput_container li {
		float: left;
		margin: 8px 8px 0 0;
	}

/* ==========================================================================
	Sizes
	========================================================================= */

	.gform_wrapper .gfield.half-field {
		padding-right: 20px;
		width: 50%;
		float: left;
		clear: none;

		input {
			width: 100% !important;
		}
	}

	.gfield.full-field {
		width: 100%;
		padding-right: 20px;
		clear: both;
		float: left;		

		input {
			width: 100% !important;
		}
	}	

/* ==========================================================================
	Hidden Labels
	========================================================================= */

	.hidden-label {
		margin-top: 1em;

		.form-wrapper & { margin-top: .5em; }
		.ginput_container { margin-top: 0 !important; }
		label { display: none !important; }
	}

/* ==========================================================================
	Confirmations
	========================================================================= */

	.gforms_confirmation_message {

		body.single-form-pages &,		
		body.contact & {		
			font-weight: 600 !important;
			color: @brand-alt !important;
		}

		body .mailing-list &,
		body .form-wrapper & {
			color: #fff !important;
		}
	}

/* ==========================================================================
	Single Line Form // Mailing List
	========================================================================= */

	.single-line-form_wrapper {
		display: inline-block;
		padding: 0;
		width: 275px;

		margin: 0 0 0 10px !important;
		font-size: 0;

		* {
			line-height: 1;
		}

		.gform_body {
			display: inline-block;
		    width: 100%;
		}

		.gform_footer {
			padding: 0 0 0 8px !important;
			margin: 0 !important;
			position: absolute;
		    top: 25px; // magic number
			right: 0px;
			border-left: 1px solid @gray-light;
		}
		@media (max-width: 641px) {
			.gform_footer {
				right:15px;
			}
		}

		.gform_fields {
			list-style: none;
		}

		.gfield {
			.base-txt;
			margin-top: 0;
			padding: 0 6px;

			input[type="text"], 
			input[type="url"], 
			input[type="email"], 
			input[type="tel"], 
			input[type="number"] {
				width: 100%;
				height: 30px;
				padding-right: 37px !important;
				line-height: 30px;
				border-radius: 4px;
				border: 0;

				.sm-txt !important;
			}
		}

		input[type="submit"] {
			width: 20px;
			height: 20px;
			padding: 0;
			margin: 0;

			.base-txt !important;
			line-height: 15px;
			text-align: center;
			color: #fff;

			border: 0;
			border-radius: 50%;
			background-color: @brand-alt;
		}


		.gform_wrapper& {
	
			.validation_error {
				display: none;
			}
			
			li.gfield.gfield_error.gfield_contains_required {
				margin: 0 !important;
				background-color: transparent;
				border: 0;
	
				.validation_message {
					width: auto;
					padding: 0 !important;
					
					position: absolute;
					.z-top;
					top: 20px;
					left: 10px;
					margin: 0;

					background-color: white;
					pointer-events: none;
				}
			}
	
		}
	}

	@media (max-width:425px) {
		.single-line-form_wrapper .gform_footer{
			top:20px;
			right:10px;
		}
		
		.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
    		line-height: 0 !important;
    		min-height:0 !important;
  
		}
		.single-line-form_wrapper .gform_footer {
			border-left:none;
			}
	}


	.gforms_confirmation_message {
		p { margin: 0 !important; }
	}

	.single-line-form {
		.submitting & input[type="submit"] { display: none !important; }

		.gforms_confirmation_message {
			display: inline-block;

			.mailing-list & {
				color: #fff;
			}
		}
	}

	input#gform_submit_button_2 {
	    width: 20px;
	}
	.mailing-list{
		position:relative;
		top:-7px;
	}
	@media (max-width:641px) {
		.mailing-list{
		top:0px;
	}
	}

	.mailing-list .gform_footer.top_label {
    width: inherit;
}

.gform_wrapper ul.gform_fields li.gfield {
	padding-right:5px !Important;
}

/* ==========================================================================
	Download Resource (ex: salary guide) / single line
	========================================================================= */
	#gform_6 .gform_footer.top_label {
	    width: 20%;
	}
	#gform_6 #gform_submit_button_6 {
		width:20px;
	}
	@media (max-width: 641px) {
		.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
			min-height:1.2rem !important;
			line-height: 0!important;
		}
		.gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit] {
			margin:-3px !important;
		}
	}
@media (max-width:425px) {
	.gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit] {
			margin:0 !important;
		}
}
	 



/* ==========================================================================
	Chosen
	========================================================================= */

	.chosen-container, 
	.gform_wrapper .gfield .chosen-container {

		&.active,
		.chosen-single {
			.default-input-state();
			background: #fff;
			box-shadow: none;

			// caret container
			div { 
				height: auto;
				width: auto;
				padding: 0 0 0 8px !important;
				margin: 0 !important;
				position: absolute;
			    top: 7px;
				right: 10px;

				border-left: 1px solid @gray-light;

				// caret itself
				b { 
					width: 20px;
					height: 20px;
					padding: 0;
					margin: 0;

					.base-txt !important;
					line-height: 15px;
					text-align: center;
					color: #fff;

					border: 0;
					border-radius: 50%;
					background: @brand-alt;

					.rotate(90deg);

					&:before {
						content: "\203A";
					    line-height: 1;
					}
				}		
			}
		}
	}

/* ==========================================================================
	Placeholder
	========================================================================= */

	input::-webkit-input-placeholder {
	   color: @text-color;
	}
	input:-moz-placeholder {
	   color: @text-color;  
	}
	input::-moz-placeholder {
	   color: @text-color;  
	}
	input:-ms-input-placeholder {  
	   color: @text-color;  
	}