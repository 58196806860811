/* ==========================================================================
	Headings
	========================================================================= */

	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
		line-height: 1;
		color: @gray;
		
		&:first-child {
			margin-top: 0;
		}
	}

	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, 
	.h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
		color: inherit;
	}

	h1, .h1 {
		font-size: 36px;
		font-weight: 700;
		color: @brand-ter;
		
		&.super { font-size: 74px; }
	}

	h2, .h2 {
		font-size: 32px;
		font-weight: 600;
		color: @brand-primary;

		&.super { font-size: 45px; }
	}

	h3, .h3 {
		font-size: 28px;
		font-weight: 700;
		color: #000;
	}

	h4, .h4 {
		font-size: 21px;
		font-weight: 500;
		color: @brand-ter;

		&.hr {
			padding-bottom: 6px;
			border-color: @gray-light;
		}
	}

	h5, .h5 {
		font-size: 21px;
		font-weight: 700;
		color: @brand-primary;
		margin: .5em 0;
	}

	h6, .h6 {
		font-size: 21px;
		font-weight: 700;
		color: #000;

		&.alt { color: @brand-primary; }
	}

/* ==========================================================================
	Speciality Headers
	========================================================================= */

	.heading-seperator {
		position: relative;

		&[title]:before {
			content: attr( title );
			padding-right: 15px;
			
			position: absolute;
			z-index: 3;
			
			background: #fff;
			white-space: nowrap;
		}

		&[title]:after {
			content: "";
			display: block;
			width: 100%;
			
			position: absolute;
			z-index: 2;
			top: 50%;
			
			border-bottom: 1px solid @brand-primary;
		}
	}

/* ==========================================================================
	Center Separated
	========================================================================= */

	.heading-center-seperator {
		text-align: center;
		position: relative;
		display: inline-block;

		&:before,
		&:after {
			content: "";
			width: 500%;
			position: absolute;
			top: 50%;
			border-width: 0 0 1px 0;
			border-style: solid;
			opacity: .5;
		}

		&:before {
			right: 100%;
			margin-right: 25px;
		}

		&:after {
			left: 100%;
			margin-left: 25px;
		}
	}

/* ==========================================================================
	Font-sizes
	========================================================================= */

	.xx-sm-txt { font-size: (@font-size-base - 4); }
	.x-sm-txt { font-size: (@font-size-base - 4); }
	.sm-txt { font-size: (@font-size-base - 2); }
	.base-txt { font-size: @font-size-base; } // 18px	
	.lg-txt { font-size: (@font-size-base + 2); }	
	.x-lg-txt { font-size: (@font-size-base + 4); }	
	.xx-lg-txt { font-size: (@font-size-base + 6); }	

/* ==========================================================================
	Font Weights
	========================================================================= */

	.normal { font-weight: 400; }
	.medium { font-weight: 500; }
	.semi-bold { font-weight: 600; }
	.bold { font-weight: 700; }

/* ==========================================================================
	Line Height
	========================================================================= */

	.lh-one { line-height: 1; }
	.lh-sm { line-height: 1.3; }
	.lh-base { line-height: @line-height-base; }
	.lh-lg { line-height: 1.9; }

/* ==========================================================================
	Ordered Lists
	========================================================================= */

	ol {
		counter-reset: olCounter;
		list-style-type: none;
		
		li {
			&:before {
				content: counters(olCounter, ".") ". ";
				counter-increment: olCounter;
				font-weight: 700;
				color: @brand-primary;
			}
		}
	}

/* ==========================================================================
	Unordered List
	========================================================================= */

	ul {
		padding-left: 40px;
		list-style: none; /* Remove default bullets */
	  }
	  
	  ul li::before {
		content: "\2022";  
		color: @brand-primary; 
		font-weight: bold; /* If you want it to be bold */
		display: inline-block;  
		width: 1em; 
		margin-left: -1em; 
	  }
	
	
	// ul {
	// 	padding-left: 40px;
	// 	list-style: square outside url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAASElEQVQYV2NkIBIwEqmOAUXhwQKjegYGhgao5gb7CecaYQbBFaIpgsnDFSMr/I/NGfYTzoHVkKUQ2X24rQbJEOUZQsFEdDgCAEKzHAtRrbquAAAAAElFTkSuQmCC');
	// }

/* ==========================================================================
	Page Content
	========================================================================= */

	.page-content {
		:first-child { margin-top: 0; }
		:last-child { margin-bottom: 0; }
	}	