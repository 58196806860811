/* ==========================================================================
	Justifed Nav
	========================================================================= */

	.justified-nav {
		display: table;
		margin: 0;
		padding: 0;

		li,
		.menu-item {
			display: table-cell;
			width: 1%;
			float: none;
			text-align: center;
		}
	}

	.nav-link { color: inherit; }

/* ==========================================================================
	Primary Nav
	========================================================================= */

	.primary-nav {
		clear: right;
		margin: 15px 0 0;
		
		> .nav > .nav-item {
			position: relative;
			margin-right: 20px;
			color: @brand-primary;

			&:last-of-type{
				margin-right: 2px;
			}
			
			&.active { color: @brand-alt; }

			&.open .dropdown-group {
				display: block;
			}

			.dropdown-toggle {
				.caret-circle { 
					background-color: @brand-alt;
					.transition(all .3s);
				}

				&:hover { .caret-circle { background-color: @brand-primary; } }

				.icon-caret {
					left: 0;
					top: .05em;
				}
			}

			&::before{display: none !important;}
		}

		.dropdown-group {
			display: none;
			.z-top;
			position: absolute;
			top: 100%;
			padding-top: 20px; //magic number, shouldn't be
		}

		.dropdown-menu {
			width: 285px;

			.no-list;
			padding: 25px;

			border: 3px solid @gray-lighter;
			border-top: 0;

			border-radius: 0 0 3px 3px;
			background-color: fade(@brand-primary, 90%);

			.nav-item {
				margin-top: 25px;

				.x-sm-txt;
				font-weight: 400;
				text-transform: none;
				color: #fff;

				&:first-child { margin-top: 0; }

				// &:hover { color: @brand-primary-dark; }
				&.active { color: @brand-primary-dark; font-weight: 600; }
				.nav-link {font-size: 18px;}
				.nav-link:hover { color: @brand-primary-dark; }
				&::before{display: none;}
			}
		}
	}


/* ==========================================================================
	Utility Nav
	========================================================================= */

	.utility-nav {
		.nav-item {
			margin-left: 20px;
			color: @gray;

			&.active { color: @brand-alt; }
			&::before{display: none;}
		}
	}

/* ==========================================================================
	Section Nav
	========================================================================= */

	.section-nav {
		position: absolute;
		bottom: 30px;

		.nav-item {
			margin-left: 8px;

			color: @brand-primary;
			font-weight:  400;

			background-color: #fff;
			border: 1px solid @brand-primary;
			border-radius: 6px;

			&:first-child { margin-left: 0; }


			&.active {
				color: #fff;
				background-color: @brand-alt;
				border-color: @brand-alt;

				&:hover .nav-link { color: #fff; }
			}

			&:hover {
				border-color: @brand-alt;
				color: @brand-alt;
			}
			&::before{display: none;}

		}

		.nav-link {
			display: block;
			padding: 5px 12px;
			color: inherit;
		}
	}	

/* ==========================================================================
	Sub Section Nav
	========================================================================= */

	.sub-section-nav {
		.nav-item {
			position: relative;
			margin: .75em 0;
			line-height: 1.3;
			padding-left: 25px;

			.nav-link { display: block; }

			&:first-child { margin-top: 0; }

			.caret-circle {
				position: absolute;
				top: .05em;
				left: 0;
			}

			&.active {
				color: @brand-primary;
				.caret-circle { background-color: @brand-primary; }
			}
			&::before{display: none;}
		}
	}

/* ==========================================================================
	Footer Nav
	========================================================================= */

	.footer-nav-group {
		margin-top: 25px;
	}

	.footer-nav {
		.nav-item {
			margin-right: 20px;

			&:hover {
				text-decoration: underline;
			}

			&::before{display: none;}
		}
	}

/* ==========================================================================
	Burger Icon
	========================================================================= */

	.menu-icon {
		@width: 25px;
		@height: 4px;
		@color: @brand-ter;
		@space: 8px;

		width: @width;
		height: @height;
		background: @color;
		.transition(all .2s);

		&:before,
		&:after {
			content: '';
			display: block;
			width: @width;
			height: @height;
			background: @color;
			position: absolute;
			z-index: -1;
			.transition(all .2s);
		}

		&:before { top: @space; }
		&:after { top: -@space; }

		.open &,
		.nav-full & {
			background: transparent;

			&:before,
			&:after {
				top: 0;
				bottom: initial;
			}

			&:before { .rotate(-45deg); }
			&:after { .rotate(45deg); }
		}
	}
