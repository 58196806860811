@media (max-width: @screen-tablet-max) {

/* ==========================================================================
	General
	========================================================================= */

	.faux-body {
		width: 100%;
		overflow: hidden;
	}


} // End Query