/* ==========================================================================
	General
	========================================================================= */

	.site-footer {
		padding: 30px 0;
	}

	.copyright {
	}

/* ==========================================================================
	Footer Bar
	========================================================================= */

	.footer-bar {
		height: @footer-bar-height;
		line-height: @footer-bar-height;
		background-color: @brand-primary;
		color: #fff;

		a:hover {
			color: @brand-primary-dark;
		}
	}

	.single-line-form {
		.input-group();
	}

	.mailing-list.submitted .message { display: none; }

	.social-connect{
		
		a{
			display: inline-block;
			height: 31px;
			width: 31px;
			margin-left: 8px;
			svg{
				fill: #fff;
				overflow: visible;
				font-size: 17px;
				background-color:#002F6C;
				margin-bottom: -9px;
				padding: 7px;
				border-radius: 50%;

				&:hover{
					fill: rgb(23, 66, 86);
					background-color: #fff;
				}
			}
		}
	}

/* ==========================================================================
	Footer Logos
	========================================================================= */

	.footer-logo {
		width: 150px;

		svg {
			pointer-events: none;
		}
	}
	.logos{
		.footer-logo-link {
			margin-left: 25px;
			margin-top: -20px;		
		}
		a:first-of-type { margin-left: 0; }
	}

/* ==========================================================================
	Address List
	========================================================================= */

	.office-columns {
		.column {
			margin-right: 50px;
			line-height: 1.3;
			&:first-child { margin-right: 0; }
		}
	}

/* ==========================================================================
	Social
	========================================================================= */

	.footer-social {

	}

	.social-media-links {
		line-height: 100%;
		font-size: 0;
		vertical-align: middle;

		.social-icon-holder  {
			display: inline-block;
			margin-left: 10px;
			vertical-align: baseline;

			&:first-child { margin-left: 0; }
		}

		.social-icon-link {
			display: block;
			font-size: 0;
			
			.icon {
				fill: @brand-alt;
		
				&.icon-facebook {  .icon-facebook(height, 25px) }
				&.icon-twitter {   .icon-twitter(height, 25px) }
				&.icon-instagram { .icon-instagram(height, 25px) }
				&.icon-google {    .icon-google(height, 25px) }
				&.icon-linkedin {  .icon-linkedin(height, 25px) }
			}

			&:hover .icon {

			}
		}
	}
