
	.icon {
		display: inline-block;
		max-width: 100%;
		max-height: 100%;

		.transition( fill .3s );
	}

/* ==========================================================================
	Icon Mixin
	========================================================================= */

	.icon(@ratio, @dimension, @width) when(@dimension = width) {
		height: (@width * (1/@ratio));
		width: @width;
	}
		
	.icon(@ratio, @dimension, @height) when(@dimension = height) {
		width: (@height * @ratio);
		height: @height;
	}

/* ==========================================================================
	Social Icons
	========================================================================= */

	.icon-facebook(@dimension, @size: 25px)  {
		@ratio: 51/100;
		.icon(@ratio, @dimension, @size);
	}

	.icon-twitter(@dimension, @size: 25px)  {
		@ratio: 5/4;
		.icon(@ratio, @dimension, @size);
	}

	.icon-google(@dimension, @size: 25px)  {
		@ratio: 1;
		.icon(@ratio, @dimension, @size);
	}

	.icon-instagram(@dimension, @size: 25px)  {
		@ratio: 43/50;
		.icon(@ratio, @dimension, @size);
	}

	.icon-linkedin(@dimension, @size: 25px)  {
		@ratio: 50/48;
		.icon(@ratio, @dimension, @size);
	}

/* ==========================================================================
	Contact Icons
	========================================================================= */

	.icon-map(@dimension, @size: 25px)  {
		@ratio: 3/5;
		.icon(@ratio, @dimension, @size);
	}

	.icon-email(@dimension, @size: 25px)  {
		@ratio: 10/7;
		.icon(@ratio, @dimension, @size);
	}

/* ==========================================================================
	UI Icons
	========================================================================= */

	.icon-search(@dimension, @size: 25px)  {
		@ratio: 99/100;
		.icon(@ratio, @dimension, @size);
	}

	.icon-caret(@dimension, @size: 25px)  {
		@ratio: 67/149;
		.icon(@ratio, @dimension, @size);
	}

	.icon-share(@dimension, @size: 25px)  {
		@ratio: 1;
		.icon(@ratio, @dimension, @size);
	}

	.icon-menu(@dimension, @size: 25px)  {
		@ratio: 43/50;
		.icon(@ratio, @dimension, @size);
	}	

	.icon-list(@dimension, @size: 25px)  {
		@ratio: 1;
		.icon(@ratio, @dimension, @size);
	}	

	.icon-download(@dimension, @size: 25px)  {
		@ratio: 61/75;
		.icon(@ratio, @dimension, @size);
	}

/* ==========================================================================
	Custom Icons
	========================================================================= */

	// .icon-custom(@dimension, @size: 25px)  {
	// 	@ratio: 71/100;
	// 	.icon(@ratio, @dimension, @size);
	// }
