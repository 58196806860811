

.sidebar-nav {
	//	Should be applied to ul

	& > li {

		a {

			&:hover,
			&.active {
				color: @brand-primary;
				background: transparent;
			}
		}
	}

	.subnav {
		//	Should be applied to a ul within an li.
		display: none;

		& > li {
			a {


				&:hover {

				}

				&:before {

				}
			}

			&.active {
				a {

				}
			}
		}
	}
}