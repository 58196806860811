@media (min-width: @screen-tablet-min)  {

/* ==========================================================================
	Columns
	========================================================================= */

	.five-columns {
		.column-container(5, 6px);
	}

	.four-columns {
		.column-container(4);
	}

	.three-columns {
		.column-container(3, 50px);
	}

	.two-columns {
		.column-container(2, 10px);
	}

	.two-columns-flex {
		.column-count(2);
		.column-gap(15px);
	}

/* ==========================================================================
	Bordered Column
	========================================================================= */

	.bordered-columns {		
		.column {
			border-left: 1px solid;
			border-color: inherit;

			.two-columns &:nth-child( 2n+1 ),
			.three-columns &:nth-child( 3n+1 ),
			.four-columns &:nth-child( 4n+1 ),
			.five-columns &:nth-child( 5n+1 ),
			&:first-child {
				border-left: 0;
			}
		}
	}

/* ==========================================================================
	Testimonials
	========================================================================= */

	.testimonial {
		border-top: 5px solid @gray-lighter;
		padding: 30px 0 40px;

		.quote {
			width: 70%;
		}

		.logo-container {
			width: 30%
		}
	}


/* ==========================================================================
	Featured Post
	========================================================================= */

	.featured-post {
		.post-info {
			&:after {
				content: none;
			}
		}
	}

} // End Query