@media (max-width: @screen-desktop-min) {
	/* ==========================================================================
	Utility
	========================================================================= */

	.hidden-phone {
		display: none;
	}

	.phone-left {
		float: left;
	}
	.phone-right {
		float: right;
	}

	.horizontal-list {
		&.vertical-phone > * {
			float: none;
		}
	}

	.container {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}

	.grid-list-reset-phone {
		width: 100%;
		overflow: initial;
		position: static;
	}

	.phone-stretch {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.phone-gutter-reset {
		padding-left: 15px;
		padding-right: 15px;
	}

	/* ==========================================================================
	Columns
	========================================================================= */

	.secondary-column,
	.primary-column {
		width: 100%;
		float: none;
	}

	.secondary-column {
		.primary-column + & {
			padding-left: 0;
		}
		&.sm + .primary-column {
			width: 100%;
		}

		&.separated,
		&.separated-right {
			padding-right: 0;
			padding-left: 0;
			margin-right: 0;
			margin-left: 0;
			border-right: 0;
			border-left: 0;

			&.sm {
				width: 100%;
			}
		}
	}

	/* ==========================================================================
	Collapsing Nav
	========================================================================= */

	.mobile-header-actions,
	.collapsing-nav {
		display: block;
		max-height: 0;
		width: 100%;
		overflow: hidden;
		margin: 0;

		.transition( all 0.3s );

		.nav {
			margin: 0;
		}

		&.open {
			max-height: 600px;
			// margin-bottom: 40px;
			&.icon {
				fill: @brand-primary;
			}
		}

		.nav-item {
			margin: 0;
		}
		.nav-link {
			display: block;
		}

		.collapsing-nav-trigger {
			display: block;
			position: absolute;
		}

		.icon {
			.transition(all 0.2s);
		}
	}

	.mobile-header-actions.open,
	.collapsing-nav.open .section-nav-list {
		margin-bottom: 40px;
	}
	.mobile-header-actions.open {
		margin-bottom: 0;
	}

	.mobile-header-actions {
		&.open {
			max-height: 1000px;
		}
	}

	/* ==========================================================================
	Header
	========================================================================= */

	.primary-header {
		height: auto;
		position: relative;

		& + *,
		.ribbon-open &,
		.ribbon-open & + * {
			top: 0;
			margin-top: 0;
		}

		.collapsing-nav-trigger {
			position: absolute;
			padding: 15px 0;
			top: 46px;
			right: 15px;
		}
	}

	.primary-logo {
		display: inline-block;
		margin-left: 15px;
		margin-bottom: 15px;
	}

	.site-logo {
		width: 175px;
	}

	.mobile-header-trigger {
		position: absolute;
		right: 15px;
		top: 40px;
	}

	/* ==========================================================================
	Header Ribbon
	========================================================================= */

	.header-ribbon {
		position: static;

		.alt-site-logo {
			margin-left: 0;
		}

		.close-link {
			top: 0;
			right: 15px;
		}

		.open-link {
			top: 54px;
			right: 15px;
		}
	}

	/* ==========================================================================
	Text
	========================================================================= */

	h2.super,
	.h2.super {
		font-size: 32px;
	}

	/* ==========================================================================
	Header Search
	========================================================================= */

	.header-search {
		width: 100%;
		padding: 8px 15px;
		margin: 0;
		background-color: @brand-alt;

		.input {
			-webkit-appearance: none;
		}
	}

	/* ==========================================================================
	Nav
	========================================================================= */

	.utility-nav,
	.primary-nav {
		margin: 0;
		> .nav {
			width: 100%;

			.nav-link {
				padding: 8px 0;
				color: #fff;
				text-align: center;
				&:hover {
					color: #fff !important;
				} // (
			}

			.nav-item {
				.base-txt;
				font-weight: 300;
				margin: 0;
				border-top: 1px solid lighten(@brand-alt, 15%);
			}

			> .nav-item {
				width: 100%;

				> .nav-link {
					background-color: @brand-alt;
				}
			}

			.dropdown-group {
				width: 100%;
				padding: 0;
				position: static;
			}

			.dropdown-menu {
				width: 100%;
				padding: 0;
				border: 0;

				.nav-item {
					border-color: lighten(@brand-primary, 15%);
				}
			}
		}
	}

	/* ==========================================================================
	Nav // Section Nav
	========================================================================= */

	.section-header,
	.section-header-container {
		height: auto;
		min-height: 65px;
		background-size: 100%;
	}

	.section-nav {
		padding-top: 45px;
		// margin-top: -25px;
		position: relative;
		// top: 0;

		.collapsing-nav-trigger {
			width: 30px;
			height: 30px;

			top: 0;
			right: 0;
			left: 0;
			.z-top;
			margin-left: 15px;

			border-radius: 50%;
			background-color: @brand-alt;

			.menu-icon {
				margin: 0 auto;
				position: relative;
				top: 14px;

				&,
				&:before,
				&:after {
					width: 20px;
					height: 3px;
					background-color: #fff;
				}

				&:before,
				&:after {
					.open& {
						top: 0;
					}
				}

				&:before {
					top: 6px;
				}
				&:after {
					top: -6px;
				}

				.open& {
					background-color: transparent;
				}
			}
		}

		.nav-item {
			width: 100%;
			margin: 0;
			border-radius: 0;
			text-align: center;

			.nav-link {
				padding: 8px 15px;
			}
		}
	}

	/* ==========================================================================
	Sub Section Nav
	========================================================================= */

	.sub-section-nav {
		border-bottom: 5px solid @gray-lighter;
		padding-bottom: 25px;
		margin-bottom: 25px;

		.nav-item:last-child {
			margin-bottom: 0;
		}
	}

	/* ==========================================================================
	Footer
	========================================================================= */

	.footer-bar {
		height: auto;
		text-align: center;

		.message {
			display: block;
			line-height: 1.6;
		}
	}

	.office-columns {
		.clearfix;

		.column {
			margin: 25px 25px;

			float: left;

			&:first-child {
				padding-right: 10px;
			}
		}
	}

	.logos {
		.no-font;

		.footer-logo-link {
			width: 50%;
			margin: 0;
			&:first-child {
				padding-right: 15px;
			}
		}

		.footer-logo {
			width: 100%;
		}
	}

	.footer-nav {
		text-align: center;

		.nav-item {
			margin-left: 10px;
			margin-right: 0;
			display: inline-block;
			float: none;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	/* ==========================================================================
	Masthead
	========================================================================= */

	.frontpage-masthead {
		position: relative;

		.banner-message {
			width: 100%;
		}

		.heading {
			font-size: 30px;
		}

		.list-columns {
			.column-item {
				width: 100%;
			}
		}

		.btn-group {
			margin-top: 15px;

			.btn {
				display: block;
				margin: 8px 0;
			}
		}
	}

	/* ==========================================================================
	Frontpage Features
	========================================================================= */

	.frontpage-features {
		.column {
			margin-top: 25px;
		}
	}

	/* ==========================================================================
	Frontpage Highlight
	========================================================================= */

	.frontpage-highlight {
		.content {
			font-size: 18px;
		}
	}

	/* ==========================================================================
	Section Header
	========================================================================= */

	.section-heading {
		font-size: 32px;
		max-width: 100%;
	}

	.map-canvas {
		height: 175px;
	}

	/* ==========================================================================
	Features
	========================================================================= */

	.simple-features {
		.feature {
			width: 100%;

			.slide {
				padding: 15px;
			}
			&:hover {
				// .slide {}
			}
		}
	}

	/* ==========================================================================
	Post List // Blog
	========================================================================= */

	.featured-post {
		.post-info {
			width: 100%;
			background-color: transparent;

			&:after,
			&:before {
				width: 100%;
				right: 0;
			}

			&:after {
				background-color: fade(@brand-ter, 80%);
			}
		}

		.post-title {
			margin: 0.5em 0 1em;
			font-size: 40px;
		}
	}

	.blog-list {
		width: 100%;
		position: static;

		.post-item {
			width: 100%;
		}
	}

	.post-inner {
		.post-title {
			margin: 30px 0 0;
		}
	}

	/* ==========================================================================
	Blog Post // Single
	========================================================================= */

	.post-header {
		.post-title {
			padding: 0;
		}

		.back-link {
			display: inline-block;
			margin-bottom: 1em;
			position: static;
		}

		.post-cta {
			text-align: center;
			.base-txt;
		}
	}

	/* ==========================================================================
	Testimonials
	========================================================================= */

	.testimonial {
		border-top: 1px solid @gray-lighter;
		padding: 25px 0;

		.quote {
			margin-top: 15px;
		}
	}

	/* ==========================================================================
	Team
	========================================================================= */

	.team-grid {
		width: 100%;
		position: static;
		display: block;

		.team-item {
			width: 100%;
			margin: 10px 0;
		}

		.bio-content {
			.z-top;

			.bio-img {
				max-width: 100%;
			}

			.back-link {
				margin: 25px 0;
			}
		}

		.bio-header {
			margin-top: 15px;
		}

		.open .bio-content {
			top: 0;
		}
	}

	/* ==========================================================================
	Image Grid
	========================================================================= */

	.img-grid {
		.grid-item {
			width: 100%;
		}
	}

	/* ==========================================================================
	Events
	========================================================================= */

	.events-group {
		padding-bottom: 25px;
		margin-top: 25px;
		margin-bottom: -5px;
		border-bottom: 5px solid @gray-light;

		.more-link-header {
			margin-bottom: 0;
		}

		.event-item {
			margin-top: 15px;
		}
	}

	.events-carousel {
		height: auto;

		.carousel-caption {
			display: block;
			position: static;
			line-height: 1.6;
		}
	}

	/* ==========================================================================
	Contact
	========================================================================= */

	#field_1_5 {
		// there's no other class on this!? c'mon GF
		width: 100%;
	}
}
