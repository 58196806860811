
.input-group(@height: 35px) {
	position: relative;

	label {
		position: absolute;
		left: -200%;
		top: -200%;
		visibility: hidden;
	}

	.input-container {
		width: 100%;
	}

	.input {
		width: 100%;
		height: @height;
		padding: 3px 6px;
		outline: 0;

		.box-sizing(border-box);
	}

	.submit {
		width: auto;
		height: @height;
		border: 0;

		position: absolute;
		top: 0;
		right: 0;

		.transition(all .2s);
	}
}
